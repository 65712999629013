<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Pedidos</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h3>{{ this.user.usu_nome }}</h3>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="success" dark class="mb-1" @click="atualizarDados">
                <v-icon dark>sync</v-icon> Atualizar
            </v-btn>
            <v-btn color="blue-grey" dark class="mb-1 ml-2" :to="{name: 'users'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="loadOrdersUser" :search="search" :rowsPerPageItems="rowsPerPageItems">
            <template v-slot:items="props">
                <td>{{ props.item.stor_or_codigo }}</td>
                <td>{{ props.item.stor_or_tid }}</td>
                <td>{{ props.item.stor_or_data }}</td>
                <td>{{ props.item.stor_or_forma_pagto }}</td>
                <td>{{ props.item.stor_or_situacao }}</td>
                <td>{{ props.item.stor_or_situacao_pagto }}</td>
                <td class="text-xs-center">
                    <v-btn flat icon color="lime lighten-1" @click.prevent="detailStoreOrder(props.item)">
                        <v-icon dark>reorder</v-icon>
                    </v-btn>
                </td>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>
    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "OrderUserComponent",
    props: {
        usu_id: {
            require: true
        }
    },
    created() {
        this.$store.dispatch('loadOrdersUser', this.usu_id);
    },
    computed: {
        loadOrdersUser() {
            if (this.$store.state.users.orders.data == null)
                return []

            this.user = this.$store.state.users.orders.data[0];
            return this.$store.state.users.orders.data[0].orders;
        }
    },
    data() {
        return {
            user: {
                usu_nome: ''
            },
            search: '',
            rowsPerPageItems: [10, 20, 30, 50, {
                "text": "$vuetify.dataIterator.rowsPerPageAll",
                "value": -1
            }],
            headers: [{
                    sortable: false,
                    text: 'Pedido Nº',
                    value: 'stor_or_codigo'
                },
                {
                    sortable: false,
                    text: 'TID',
                    value: 'stor_or_tid'
                },
                {
                    sortable: false,
                    text: 'Data',
                    value: 'stor_or_data'
                },
                {
                    sortable: false,
                    text: 'Forma de Pagto',
                    value: 'stor_or_forma_pagto'
                },
                {
                    sortable: false,
                    text: 'Situação',
                    value: 'stor_or_situacao'
                },
                {
                    sortable: false,
                    text: 'Situação Pagto',
                    value: 'stor_or_situacao_pagto'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            storeorder: [],
            base_url: URL_BASE
        }
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch('loadOrdersUser', this.usu_id);
        },
        detailStoreOrder(item) {
            this.$router.push({
                name: 'store-order.detail',
                params: {
                    stor_or_id: item.stor_or_id
                }
            });
        },
    },
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
